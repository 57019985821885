import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';
import { routes } from './app.routes';
import { provideHttpClient, withInterceptorsFromDi, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BaseURLInterceptor } from './api/interceptors/base-url.interceptor';
import { AuthInterceptor } from './api/interceptors/auth.interceptor';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from './material/material.module';
import { AppStores } from './api/services/stores';

export const appConfig: ApplicationConfig = {
  providers: [
    AppStores,
    provideRouter(routes),
    importProvidersFrom(
      AppStores,
      MaterialModule,
      BrowserModule,
    ),
    provideAnimations(),
    provideHttpClient(
      withInterceptorsFromDi(),
    ),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BaseURLInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ]
};
