import { Injectable, computed, inject, signal } from "@angular/core";
import { BaseService } from "../base.service";
import { DomainStore } from "./domains.store";

@Injectable({
  providedIn: 'root'
})

export class DomainsService extends BaseService {
    domainsStore = inject(DomainStore);

    //Domain Grid
    getDomainsGrid(params: any) {
      this.domainsStore.setLoading(true);

      return this.http.get('Report/domains-grid', {params: params})
        .subscribe((domainsGrid: any) => {
          this.domainsStore.setAll(domainsGrid);
          this.domainsStore.setLoading(false);
        });
    }
}