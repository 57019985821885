
import {
    patchState,
    signalStore,
    withMethods,
    withState,
  } from '@ngrx/signals';
  import { Dashboard, initialState } from './dashboard.model';
  
  export const DashboardStore = signalStore(
    withState(initialState),
  
    withMethods(({...store}) => ({
      setAll(dashboardSignal: Dashboard["dashboardSignal"]) {
        patchState(store, {dashboardSignal});
      },
      setLoading(isLoading: boolean) {
        patchState(store, {loadingSignal: isLoading});
      },
      resetStore() {
        patchState(store, initialState);
      }
    }))
  );