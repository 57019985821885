import { Injectable, computed, inject, signal } from '@angular/core';
import { BaseService } from '../base.service';
import { LearnerReportsStore } from './learner-reports/learner-reports.store';
import { QuarterlyReportsStore } from './quarterly-reports/quarterly-reports.store';


@Injectable({
  providedIn: 'root',
})
export class ReportsService extends BaseService {
  learnerReportsStore = inject(LearnerReportsStore);
  quarterlyReportsStore = inject(QuarterlyReportsStore);

  //////////////////////////
  //    Learner Reports   //
  //////////////////////////

  getStateLearnersGrid(params: any) {
    this.learnerReportsStore.setStateLearnersGridLoading(true);

    return this.http.get('Report/state-learner-grid', { params: params }).subscribe((stateLearnersGrid: any) => {
      this.learnerReportsStore.setStateLearnersGrid(stateLearnersGrid);
      this.learnerReportsStore.setStateLearnersGridLoading(false);
    });
  }

  getLearnerMetrics(params: any) {
    this.learnerReportsStore.setLearnerMetricsLoading(true);

    return this.http.get('Report/learner-metrics', { params: params }).subscribe((learnerMetrics: any) => {
      this.learnerReportsStore.setLearnerMetrics(learnerMetrics);
      this.learnerReportsStore.setLearnerMetricsLoading(false);
    });
  }

  //////////////////////////
  //   Training Reports   //
  //////////////////////////

  getTrainingReport(paramsRequested: any) {
    return this.http.get('Report/training-metrics', {
      params: paramsRequested,
    });
  }

  exportTrainingReport(paramsRequested: any) {
     return this.http.get('Report/training-metrics/export', {
      params: paramsRequested,
    });
  }

  //////////////////////////
  //  Interaction Reports //
  //////////////////////////

  //////////////////////////
  //   Quarterly Reports  //
  //////////////////////////

  getQuarterLearnerRegistration(params: any) {
    this.quarterlyReportsStore.setQuarterLearnerRegistrationLoading(true);

    return this.http
      .get('Report/learner-registration', { params: params })
      .subscribe((quarterLearnerRegistration: any) => {
        this.quarterlyReportsStore.setQuarterLearnerRegistration(quarterLearnerRegistration);
        this.quarterlyReportsStore.setQuarterLearnerRegistrationLoading(false);
      });
  }

  getTotalLearnerRegistration(params: any) {
    this.quarterlyReportsStore.setTotalLearnerRegistrationLoading(true);

    return this.http
      .get('Report/learner-registration', { params: params })
      .subscribe((totalLearnerRegistration: any) => {
        this.quarterlyReportsStore.setTotalLearnerRegistration(totalLearnerRegistration);
        this.quarterlyReportsStore.setTotalLearnerRegistrationLoading(false);
      });
  }

  getQuarterCourseRegistration(params: any) {
    this.quarterlyReportsStore.setQuarterCourseRegistrationLoading(true);

    return this.http
      .get('Report/course-registration', { params: params })
      .subscribe((quarterCourseRegistration: any) => {
        this.quarterlyReportsStore.setQuarterCourseRegistration(quarterCourseRegistration);
        this.quarterlyReportsStore.setQuarterCourseRegistrationLoading(false);
      });
  }

  getTotalCourseRegistration(params: any) {
    this.quarterlyReportsStore.setTotalCourseRegistrationLoading(true);

    return this.http.get('Report/course-registration', { params: params }).subscribe((totalCourseRegistration: any) => {
      this.quarterlyReportsStore.setTotalCourseRegistration(totalCourseRegistration);
      this.quarterlyReportsStore.setTotalCourseRegistrationLoading(false);
    });
  }

  getQuarterCourseCertificate(params: any) {
    this.quarterlyReportsStore.setQuarterCourseCertificateLoading(true);

    return this.http.get('Report/course-certificate', { params: params }).subscribe((quarterCourseCertificate: any) => {
      this.quarterlyReportsStore.setQuarterCourseCertificate(quarterCourseCertificate);
      this.quarterlyReportsStore.setQuarterCourseCertificateLoading(false);
    });
  }

  getTotalCourseCertificate(params: any) {
    this.quarterlyReportsStore.setTotalCourseCertificateLoading(true);

    return this.http.get('Report/course-certificate', { params: params }).subscribe((totalCourseCertificate: any) => {
      this.quarterlyReportsStore.setTotalCourseCertificate(totalCourseCertificate);
      this.quarterlyReportsStore.setTotalCourseCertificateLoading(false);
    });
  }

  getTopOrganizations(params: any) {
    this.quarterlyReportsStore.setTopOrganizationsLoading(true);

    return this.http.get('Report/top-orgs', { params: params }).subscribe((topOrganizations: any) => {
      this.quarterlyReportsStore.setTopOrganizations(topOrganizations);
      this.quarterlyReportsStore.setTopOrganizationsLoading(false);
    });
  }
  
  getTopDomains(params: any) {
    this.quarterlyReportsStore.setTopDomainsLoading(true);

    return this.http.get('Report/top-domains', { params: params }).subscribe((topDomains: any) => {
      this.quarterlyReportsStore.setTopDomains(topDomains);
      this.quarterlyReportsStore.setTopDomainsLoading(false);
    });
  }

  downloadQuarterlyReport(requestedParams: any) {
    return new Promise((resolve) => {
      this.http.get('report/quarterly/export', {params: requestedParams, responseType: 'blob' as 'json', observe: 'response' }).subscribe({
        next: (res: any) => {
          const fileName = res.headers.get('content-disposition')?.split('filename=')[1].split(';')[0].replaceAll("\"", "");
          const contentType = res.body.type;
          const blob = new Blob([res.body], { type: contentType });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = fileName;
          link.click();
          window.URL.revokeObjectURL(url);
          resolve(true);
        },
        error: (err) => {
          resolve(false);
        },
      });
    });
  }


}
