import { Component, OnInit } from '@angular/core';
// import { AuthQuery } from 'src/app/routes/authentication/state/auth.query';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    standalone: true,
    imports: [NgIf]
})
export class FooterComponent implements OnInit {
  date: number = new Date().getFullYear();
  loggedIn: boolean = false;

  //constructor(private authQuery: AuthQuery) {}

  ngOnInit(): void {
    // this.authQuery.userLoggedIn$.subscribe((yes) => {
    //   if (yes) {
    //     this.loggedIn = true;
    //   } else {
    //     this.loggedIn = false;
    //   }
    // });
  }
}
