import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, AbstractControlOptions, FormGroup, FormBuilder, ValidationErrors, Validators, ReactiveFormsModule, FormsModule  } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, of, Subject, takeUntil, throwError } from 'rxjs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatIcon } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { NgIf, NgClass, CommonModule, Location } from '@angular/common';
import { SnackbarComponent, ToastType } from '../../../../shared/components/layout/snackbar/snackbar.component';
import { AccountService } from '../../../../api/services/account.service';

@Component({
  selector: 'app-reset-password',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    RouterModule,
    NgIf,
    NgClass,
    CommonModule,
    MatIcon
  ],
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.scss'
})

export class ResetPasswordComponent implements OnInit {
  public form: FormGroup = Object.create(null);
  disableFlag:boolean = false;
   hide = true;
   chide = true;
   private userEmail!: string;
   private totpGuid!: string;
   private confirmationToken!: string;
   constructor(
     private fb: FormBuilder,
     private router: Router,
     private route: ActivatedRoute,
     private snackBar: MatSnackBar,
     private location: Location,
    private accountService: AccountService
   ) {}
 
   token: any = '';
 
   ngOnInit(): void {
     this.form = this.fb.group({
       email: this.fb.control(null, Validators.compose([Validators.required, Validators.email])),
       newPassword : this.fb.control(null, Validators.required),
       confirmPassword: this.fb.control(null, Validators.required),
     }, 
     {validators: [this.mustMatch('newPassword', 'confirmPassword')]} as AbstractControlOptions );
 
     this.route.queryParams.subscribe(params => {
      this.confirmationToken = params['confirmationToken'];
      this.userEmail  = params['email']?.replace(' ', '+');

      if(Object.keys(params).length == 0) {
        const routeData = this.location.getState();
          if (routeData) {
            this.userEmail = routeData['email'];
            this.totpGuid = routeData['totpGuid'];

            if (!this.userEmail || !this.totpGuid) {
              this.router.navigateByUrl('/login');
            }
          } else {
            this.router.navigateByUrl('/login');
          }
      }
    
    })
   }
 
   onSubmit() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    this.disableFlag = true;
    const validationErrorHandler = (validationErrors: ValidationErrors | any) => {
      var responseErrors = validationErrors?.error?.expiredTOTP.length ? validationErrors?.error?.expiredTOTP : validationErrors?.error?.other.length ? validationErrors?.error?.other : validationErrors;
      if (responseErrors.length) {
        responseErrors.forEach((error: any) => {
          const control = this.form.get(error.field);
          if (control) {
            this.form.markAllAsTouched();
            control.setErrors({
              message: error.message,
            });
          } else if (error?.field === 'password') {
            const control = this.form.get('newPassword');
            if (control) {
              this.form.markAllAsTouched();
              control.setErrors({
                message:
                  'The Password does not meet requirements: minimum of 6 characters and contain at least one of each of the following: uppercase letter, lowercase letter, special character, and number.',
              });
            }
          } else {
            this.snackBar.openFromComponent(SnackbarComponent, {
              duration: 3000,
              data: { toastType: ToastType.Error, message: `${error.message ?? error}` },
            });
          }
        });
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, {
          duration: 3000,
          data: { toastType: ToastType.Error, message: 'Error occured' },
        });
      }
      return of(null);
    };
    if (this.form.valid) {
      const password = this.form.get('newPassword')?.value;

      this.accountService
      .resetPassword(this.userEmail, password, this.totpGuid)
        .pipe(catchError((error) => throwError(error)))
        .subscribe(
          () => {
            this.snackBar.openFromComponent(SnackbarComponent, {
              duration: 3000,
              data: { toastType: ToastType.Success, message: 'Password Reset Successfully!' },
            });
            this.router.navigate(['/login']);
            this.disableFlag = false;
          },
          (error) => {
            this.disableFlag = false;
            validationErrorHandler(error);
            if (error.error?.expiredTOTP) {
              this.router.navigate(['/forgot']);
            }
          },
        );

    }
  }

   mustMatch(control1:any, control2:any){
      return (fg:FormGroup) => {
        const ctrl1 = fg.controls[control1];
        const ctrl2 = fg.controls[control2];
        if(ctrl2.errors && !ctrl2.errors['message']){
          return
        }

        if(ctrl1?.value !== ctrl2?.value){
          ctrl2.setErrors({mustMatch: true})
        } else {
          ctrl2.setErrors(null)

        }
      }
   }

}



