import {
    patchState,
    signalStore,
    withMethods,
    withState,
  } from '@ngrx/signals';
import { LearnerReports, learnerReportsInitialState } from './learner-reports.model';
  
export const LearnerReportsStore = signalStore(
    withState(learnerReportsInitialState),

    withMethods(({...store}) => ({
        setStateLearnersGrid(stateLearnersGridSignal: LearnerReports["stateLearnersGridSignal"]) {
            patchState(store, {stateLearnersGridSignal});
        },
        setLearnerMetrics(learnerMetricsSignal: LearnerReports["learnerMetricsSignal"]) {
            patchState(store, {learnerMetricsSignal});
        },
        setStateLearnersGridLoading(isLoading: boolean) {
            patchState(store, {stateLearnersGridLoadingSignal: isLoading});
        },
        setLearnerMetricsLoading(isLoading: boolean) {
            patchState(store, {learnerMetricsLoadingSignal: isLoading});
        },
        resetStore() {
            patchState(store, learnerReportsInitialState );
        }
    }))
);



