<div class="page-wrapper">
  <div class="header-container">
    <div>
      <app-banner></app-banner>
    </div>
    <div class="h-16">
      <mat-toolbar class="flex justify-between h-16">
        <div class="flex items-center">
          <!-- <button *ngIf="!mobileQuery.matches" class="text-primary menu-icon flex items-center mr-5"
            (click)="sideNav.toggle(); closeProfileNav()">
            <mat-icon>menu</mat-icon>
          </button> -->
            <a routerLink="dashboard" class="header-logo-icon regular-course-icon">
              <img src="assets/MandatedReporterTraining-log.svg" alt="Mandated Reporter Training" />
            </a>
        </div>
        <div>
          <a class="btn-link mx-2" (click)="logout()">Logout</a>
        </div>
        <!-- <div class="flex items-center max-h-9" *ngIf="!isAdmin">
          <div *ngIf="mobileQuery.matches">
            <p class="m-0 p-0">{{ activeOrg!.entityName }}</p>
            <p class="role-tag"> {{ organizationService.getOrgChipLabel(activeOrg) }} </p>
          </div>
          <div class="profile-img ml-2 cursor-pointer" (click)="profileSnav.toggle(); closeSideNav()">
            <div *ngIf="!activeOrg.logoImageUrl" class="h-10 w-10">
              <img *ngIf="activeOrg!.entityType == 'Individual'" src="assets/images/profile/individual-icon.svg"
                alt="Profile placeholder individual image" />
              <img *ngIf="activeOrg!.entityType == 'Organization'" src="assets/images/profile/organization-icon.svg"
                alt="Profile placeholder organization image" />
              <img *ngIf="activeOrg!.entityType == 'Coop'" src="assets/images/profile/organization-icon.svg"
                alt="Profile placeholder cooperative image" />
            </div>
            <img *ngIf="activeOrg.logoImageUrl" [src]="activeOrg.logoImageUrl" alt="Profile Picture" />
          </div>
        </div> -->
      </mat-toolbar>
    </div>
  </div>
  <div class="main-site">
    <mat-sidenav-container class="h-full">
      <mat-sidenav 
        #snav 
        id="snav" 
        [mode]="mobileQuery.matches ? 'side' : 'over'" 
        [opened]="sidebarOpened"
        (opened)="sidebarOpened = true" 
        (close)="sidebarOpened = false"
        (closed)="sidebarOpened = false"
        [ngStyle]="{ 'width': mobileQuery.matches ? '250px' : '100%' }"
        position="start">
        <app-sidebar class="app-sidebar" (eventEmitter)="closeSideNav()"></app-sidebar>
        <div class="version-text">v.{{ versionNumber.number }} rel. {{ versionNumber.date | date : 'MM.dd.yy' }}</div>
      </mat-sidenav>
    
      <mat-sidenav-content id="scrolldiv" [ngClass]="{ minitoggle: !snav.opened }">
        <div class="flex flex-col h-full px-4">
          <div class="mt-4">
            <!-- <h1>Org Admin banner goes here</h1> -->
          </div>
          <!-- <p>App Bread crumbs</p> -->
          <div class="flex-grow">
            <router-outlet>
              <app-spinner></app-spinner>
            </router-outlet>
          </div>
          <div>
            <app-footer></app-footer>
          </div>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
    
  </div>
</div>