import { Injectable, computed, signal } from "@angular/core";
import { BaseService } from "../base.service";

@Injectable({
  providedIn: 'root'
})
export class ReportsService extends BaseService {
  learnerMetrics = signal<any>({rows:[], showZeroOverlay: false, totalNumberOfItems: 0});
  organizationGrid = signal<any>({rows:[], showZeroOverlay: false, totalNumberOfItems: 0});
  stateLearnersGrid = signal<any>({rows:[], showZeroOverlay: false, totalNumberOfItems: 0});

  // Quarterly Report
  quarterLearnerRegistration = signal<number>(0);
  totalLearnerRegistration = signal<number>(0);
  quarterCourseRegistration = signal<any>(null);
  totalCourseRegistration = signal<any>(null);
  quarterCourseCertificate = signal<any>(null);
  totalCourseCertificate = signal<any>(null);
  topOrganizations = signal<any>(null);
  topDomains = signal<any>(null);



  getLearnerMetrics(params: any) {
    this.http.get('Report/learner-metrics', {params: params}).subscribe((learnerMetrics: any) => {
      this.learnerMetrics.set(learnerMetrics);
    });
  }

  // Quarterly Report
  getQuarterLearnerRegistration(params: any) {
    this.http.get('Report/learner-registration', {params: params}).subscribe((quarterLearnerRegistration: any) => {
      this.quarterLearnerRegistration.set(quarterLearnerRegistration);
    });
  }
  getTotalLearnerRegistration(params: any) {
    // only pass optional state param
    this.http.get('Report/learner-registration', {params: params}).subscribe((totalLearnerRegistration: any) => {
      this.totalLearnerRegistration.set(totalLearnerRegistration);
    });
  }
  getQuarterCourseRegistration(params: any) {
    this.http.get('Report/course-registration', {params: params}).subscribe((quarterCourseRegistration: any) => {
      this.quarterCourseRegistration.set(quarterCourseRegistration);
    });
  }
  getTotalCourseRegistration(params: any) {
    // only pass optional state param
    this.http.get('Report/course-registration', {params: params}).subscribe((totalCourseRegistration: any) => {
      this.totalCourseRegistration.set(totalCourseRegistration);
    });
  }
  getQuarterCourseCertificate(params: any) {
    this.http.get('Report/course-certificate', {params: params}).subscribe((quarterCourseCertificate: any) => {
      this.quarterCourseCertificate.set(quarterCourseCertificate);
    });
  }
  getTotalCourseCertificate(params: any) {
    // only pass optional state param
    this.http.get('Report/course-certificate', {params: params}).subscribe((totalCourseCertificate: any) => {
      this.totalCourseCertificate.set(totalCourseCertificate);
    });
  }
  getTopOrganizations(params: any) {
    this.http.get('Report/top-orgs', {params: params}).subscribe((topOrganizations: any) => {
      this.topOrganizations.set(topOrganizations);
    });
  }
  getTopDomains(params: any) {
    this.http.get('Report/top-domains', {params: params}).subscribe((topDomains: any) => {
      this.topDomains.set(topDomains);
    });
  }
  getOrganizationGrid(params: any) {
    this.http.get('Report/organization-grid', {params: params}).subscribe((organizationGrid: any) => {
      this.organizationGrid.set(organizationGrid)
    });
  }

  getStateLearnersGrid(params: any) {
    this.http.get('Report/state-learner-grid', {params: params}).subscribe((stateLearnersGrid: any) => {
      this.stateLearnersGrid.set(stateLearnersGrid)
    });
  }
}
