<div class="w-screen blank-page-min-height items-center flex flex-col justify-center mt-3">
  <div class="h-full">
      <div class="text-center mx-auto sm:w-full lg:w-3/4">
          <h1>Verify Email</h1>
          <p class="mt-4">We've sent a message to <span class="underline strong">{{ userEmail }}</span> with a six
              digit security code. If your message doesn't arrive within a few minutes, be sure to check your spam or
              all mail folder (sometimes messages get lost in there).</p>
      </div>

      <div class="text-center mx-auto mt-8 sm:w-full lg:w-1/2">
          <h2 class="learner">Enter Verification Code</h2>
          <p>Open your email and enter the 6-digit code below.</p>

          <form class="flex flex-col w-full items-center mt-8" [formGroup]="form" (ngSubmit)="onSubmit()">
              <mat-form-field id="VerificationInput" class="mx-auto sm:w-full lg:w-1/2">
                  <div class="w-100">
                      <input matInput type="text" class="w-full" formControlName="verificationCode"
                          placeholder="Verification Code" required maxlength="7" mask="000-000">
                  </div>
              </mat-form-field>
              <mat-error
                  *ngIf="form.controls['verificationCode'].errors && form.controls['verificationCode'].touched">
                  That code didn't work
              </mat-error>
              <input matInput type="email" class="hidden" formControlName="email">
              <button type="submit" [disabled]="disableFlag" class="btn-primary large mt-8" [disabled]="disableFlag">
                  Verify
              </button>
          </form>
          <p class="mt-8"><button (click)="resendTotp()" class="primary-link" [disabled]="disableResend">Resend code.</button></p>
      </div>
  </div>
</div>