<div class="flex flex-col w-full" [formGroup]="filtersForm">
    <div class="flex flex-row justify-between items-center">
      <div>
        <div class="flex items-end w-full">
          <h1 class="learner pr-2">Quarterly Report</h1>
          <!-- <div *ngIf="lookupsService.statesLookup().length >= 1 &&  currentState != '' "> -->
            <div>
            <a class="primary-link ml-4" (click)="downloadReport()" >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M280 24c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 270.1-95-95c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9L239 369c9.4 9.4 24.6 9.4 33.9 0L409 233c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-95 95L280 24zM128.8 304L64 304c-35.3 0-64 28.7-64 64l0 80c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-80c0-35.3-28.7-64-64-64l-64.8 0-48 48L448 352c8.8 0 16 7.2 16 16l0 80c0 8.8-7.2 16-16 16L64 464c-8.8 0-16-7.2-16-16l0-80c0-8.8 7.2-16 16-16l112.8 0-48-48zM432 408a24 24 0 1 0 -48 0 24 24 0 1 0 48 0z"/></svg> 
              Export</a>
          </div>
        </div>

        <div class="mt-3">
          <h2 class="learner">{{ reportingPeriod }} <span *ngIf="currentState != ''">- State of</span> {{ currentState }}</h2>
        </div>
      </div>



      
      <div class="flex flex-row flex-wrap">
        <div class="w-full lg:w-1/2" *ngIf="lookupsService.statesLookup().length > 1">
          <mat-form-field class="w-full pr-4">
            <mat-label>State</mat-label>
            <mat-select formControlName="state" panelClass="mat-select-mt3">
              <mat-option [value]="null">All</mat-option>
              <mat-option *ngFor="let state of lookupsService.statesLookup()" [value]="state.text"
                >{{ state.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="w-full " [ngClass]="lookupsService.statesLookup().length > 1 ? 'lg:w-1/2' : ''">
          <mat-form-field class="w-full pr-4">
            <mat-label>Report Period</mat-label>
            <mat-select formControlName="yearQuarter" panelClass="mat-select-mt3">
              <mat-option *ngFor="let yearQuarter of yearQuarters" [value]="yearQuarter.value"
                >{{ yearQuarter.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
</div>


<div class="dashboard mt-8">
  <div class="left-column">
    <div class="card">

      <div class="w-full text-center">
        <h2 class="learner">Total Registrations</h2>
      </div>
      <div class="w-full flex justify-between">
        <div>
          <h3 class="learner">Learner Registrations</h3>
          <p>Total number of new learners registered</p>
        </div>
        <div class="flex">
          <div>Period<br><strong>{{ reportsService.quarterLearnerRegistration() | number }}</strong></div>
          <div class="ml-8">All Time<br><strong>{{ reportsService.totalLearnerRegistration() | number }}</strong></div>
        </div>
      </div>

      <div class="w-full mt-8">
        <a routerLink="/learners" class="primary-link underline">View Learners</a>
      </div>
    </div>

    
    
   


    <div class="card mt-8">

      <div class="w-full text-center">
        <h2 class="learner">Total Registrations & Certificates</h2>
      </div>
      <div class="w-full flex justify-between">
        <div>
          <h3 class="learner">Course Registrations</h3>
          <p>Total number of unique course sessions.</p>
        </div>
        <div class="flex">
          <div>Period<br><strong>{{ (reportsService.quarterCourseRegistration() ? reportsService.quarterCourseRegistration().tally : null) | number }}</strong></div>
          <div class="ml-8">All Time<br><strong>{{ (reportsService.totalCourseRegistration() ? reportsService.totalCourseRegistration().tally : null) | number }}</strong></div>
        </div>
      </div>

      <div class="w-full flex justify-between mt-4">
        <div>
          <h3 class="learner">Certificates</h3>
          <p>Total number of unique certificates issued.</p>
        </div>
        <div class="flex">
          <div><strong>{{ (reportsService.quarterCourseCertificate() ? reportsService.quarterCourseCertificate().tally : null) | number }}</strong></div>
          <div class="ml-8"><strong>{{ (reportsService.totalCourseCertificate() ? reportsService.totalCourseCertificate().tally : null) | number }}</strong></div>
        </div>
      </div>

      <div class="w-full mt-8">
        <a routerLink="/report/training" class="primary-link underline">View Training Reports</a>
      </div>
    </div>


    
  </div>

  <!-- Right Column (Card 3 with Chart) -->
  <div class="right-column">
    <div class="card chart-card">
      <h2 class="learner">Certificates Issued by Training</h2>
      <div class="chart-container">
        <apx-chart
          class="pie-chart"
          [series]="chartSeries"
          [chart]="chartOptions.chart"
          [labels]="chartOptions.labels"
          [colors]="chartOptions.colors">
        </apx-chart>
      </div>
    </div>
  </div>
</div>


<div class="organization-subscription w-full justify-center mt-8">
  <div class="dashboard-card">
    <div class="text-left" [ngStyle]="{ 'width': chartWidth + 'px' }">
      <h2 class="learner">Organization Revenue</h2>
    </div>
    <apx-chart
      [series]="monthlyTrainingMetrics.series"
      [chart]="monthlyTrainingMetrics.chart"
      [dataLabels]="monthlyTrainingMetrics.dataLabels"
      [plotOptions]="monthlyTrainingMetrics.plotOptions"
      [xaxis]="monthlyTrainingMetrics.xaxis"
      [yaxis]="monthlyTrainingMetrics.yaxis"
      [colors]="monthlyTrainingMetrics.colors"
      [tooltip]="monthlyTrainingMetrics.tooltip"
    ></apx-chart>
    <div class="text-left" [ngStyle]="{ 'width': chartWidth + 'px' }">
      <p>
        <a routerLink="/report/training" class="primary-link underline">View Training Report</a>
      </p> 
      
    </div>
  </div>
</div>


<div class="w-full grid-container mt-8">
  <section>
    <h2 class="learner">Top Performing Organizations</h2>
    <table class="data-table">
      <thead>
        <tr>
          <th>Org Name</th>
          <th>Category</th>
          <th>Registered Learners</th>
          <th>Certificates</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let org of this.reportsService.topOrganizations()">
          <td>{{ org.description1.name }}</td>
          <td>{{ org.description1.description }}</td>
          <td>{{ org.count1.total | number }}</td>
          <td>{{ org.count2.total | number }}</td>
        </tr>
      </tbody>
    </table>
    <a routerLink="/organization" class="primary-link underline">View Organizations</a>
  </section>

  <section class="my-8">
    <h2 class="learner">Top Domains For Registered Learners</h2>
    <table class="data-table">
      <thead>
        <tr>
          <th>Domain</th>
          <th>Learners</th>
          <th>Course Registrations</th>
          <th>Certificates</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let domain of this.reportsService.topDomains()">
          <td>{{ domain.description1.name }}</td>
          <td>{{ domain.count1.total | number }}</td>
          <td>{{ domain.count2.total | number }}</td>
          <td>{{ domain.count3.total | number }}</td>
        </tr>
      </tbody>
    </table>
    <a routerLink="/learners" class="primary-link underline">View Learners</a>
  </section>
</div>
