import { Component, effect, inject } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MRTGridTemplateComponent } from '@mrt/mrt-grid-template';
import { GridOptions } from '@mrt/mrt-grid-template/lib/interfaces/grid-options';
import { LookupsService } from '../../api/services/lookups/lookups.service';
import { LookupsStore } from '../../api/services/lookups/lookups.store';
import { ReportsService } from '../../api/services/reports/reports.service';
import { Sort } from '@angular/material/sort';
import { DomainStore } from '../../api/services/domains/domains.store';
import { DomainsService } from '../../api/services/domains/domains.service';

@Component({
  selector: 'app-domains',
  standalone: true,
  imports: [MRTGridTemplateComponent],
  templateUrl: './domains.component.html',
  styleUrl: './domains.component.scss'
})
export class DomainsComponent {
  readonly lookupsStore = inject(LookupsStore);
  readonly domainStore = inject(DomainStore);

  defaultSortColumn: string = 'createDate';
  defaultSortDirection: string = 'DESCENDING';
  paramsReq: any;
  gridOptions: GridOptions = {
    filterData: [
      { name: 'From', formControlName: 'fromDate', type: 'date', dateOffset: -365 },
      { name: 'To', formControlName: 'toDate', type: 'date', dateOffset: 0 },
      { name: 'Search', formControlName: 'search', type: 'search' },
    ],
    openFilters: true,
    columns: [
      { header: 'Domain', controlName: 'domain' },
      { header: 'Learners', controlName: 'learners' },
      { header: 'Course Registrations', controlName: 'courseRegistrations'},
      { header: 'Certificates', controlName: 'certificates' }
    ],
    displaySearch: true,
    data: new MatTableDataSource<any>(),
    totalNumberOfItems: 0,
    allowExportCSV: false,
    disableflag: false,
    isLoading: false,
    fetchData: false,
  };

  constructor (
    private lookupsService: LookupsService,
    private domainsService: DomainsService,
  ) {
    effect(() => {
      this.gridOptions.isLoading = false;
      this.gridOptions.data.data = this.domainStore.domainsSignal().rows;
      this.gridOptions.totalNumberOfItems = this.domainStore.domainsSignal().totalNumberOfItems;
    });
  }

  ngOnInit() {
    this.gridOptions.fetchData = true; 
  }

  fetchAll(rawParams: any = {}) {
    this.gridOptions.isLoading = true;

    const { search, fromDate, toDate, sortOrder, pageSize, pageNumber } = rawParams;
    const fromDateISO = new Date(fromDate).toISOString();
    const toDateISO = new Date(toDate).toISOString();
    const newSortOrder = this.sortField(sortOrder);
    const params = {
      PageSize: pageSize ?? 20,
      PageNumber: pageNumber ?? 1,
      SearchTerm: search?.length! > 0 ? search : '',
      FromDate: fromDateISO ?? '',
      ToDate: toDateISO ?? '',
      SortField: newSortOrder
        ? newSortOrder.active && newSortOrder.direction
          ? newSortOrder.active
          : this.defaultSortColumn
        : this.defaultSortColumn,
      SortDirection: newSortOrder ? newSortOrder.direction ?? this.defaultSortDirection : this.defaultSortDirection,
    };

    this.paramsReq = params;
    this.domainsService.getDomainsGrid(params);
  }

  sortField(sort: Sort) {
    let activeSort: any = this.defaultSortColumn;
    let direction: any = this.defaultSortDirection;
    if (sort !== null && sort !== undefined) { 
      switch (true) {
        case sort.active === 'domain':
          activeSort = 'Domain';
          break;
        case sort.active === 'learners':
          activeSort = 'RegisteredUsers';
          break;
        case sort.active === 'courseRegistrations':
          activeSort = 'CourseRegistrations';
          break;
        case sort.active === 'certificates':
          activeSort = 'Certificates';
          break;
      }
      switch (true) {
        case sort.direction === 'desc':
          direction = 'DESCENDING';
          break;
        case sort.direction === 'asc':
          direction = 'ASCENDING';
          break;
        case sort.direction === '':
          direction = null;
          break;
      }
    }
    return { active: activeSort, direction: direction };
  }

  actionEvent(event: any) {
    if (event.action === 'fetchAll') {
      this.fetchAll(event.data);
    } else if (event.action === 'exportCSV') {
      // this.exportCsv();
    } else if (event.action === 'formChanges') {
      // this.formChanges(event.data);
    } else if (event.action === 'clearFilters') {
      // this.clearFilters();
    } // 'edit' is specific to this component 
    else if (event.action === 'edit') {
      // this.openModal(event.data);
    }
  }
}

