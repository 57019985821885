import { Injectable } from '@angular/core';
import { BaseService } from './base.service';

export enum TotpType {
  VerifyEmail = 1,
  VerifyForgotPassword = 2,
  Validate = 3,
}

@Injectable({
  providedIn: 'root'
})
export class TotpService extends BaseService {
  totpConfirmEmail(email: string, code: string) {
    return this.http.post('/TOTP/confirm-email', { email, code });
  }

  totpValidate(email: string, code: string) {
    return this.http.post('/TOTP/validate', { email, code });
  }

  generateTotp(email: string, totpType: TotpType) {
    return this.http.post('/TOTP/generate', { email, totpType});
  }

  totpResend(email: string, totpType: number) {
    return this.http.post('/TOTP/resend', { email, totpType });
  }
}
