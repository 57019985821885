export interface QuarterlyReports {
    quarterLearnerRegistrationSignal: number | null;
    totalLearnerRegistrationSignal: number | null;
    quarterCourseRegistrationSignal: any | null;
    totalCourseRegistrationSignal: any | null;
    quarterCourseCertificateSignal: any | null;
    totalCourseCertificateSignal: any | null;
    topOrganizationsSignal: any | null;
    topDomainsSignal: any | null;
    loadingQuarterLearnerRegistrationSignal: boolean;
    loadingTotalLearnerRegistrationSignal: boolean;
    loadingQuarterCourseRegistrationSignal: boolean;
    loadingTotalCourseRegistrationSignal: boolean;
    loadingQuarterCourseCertificateSignal: boolean;
    loadingTotalCourseCertificateSignal: boolean;
    loadingTopOrganizationsSignal: boolean;
    loadingTopDomainsSignal: boolean;
  }
  
  export const quarterlyReportsInitialState: QuarterlyReports = {
    quarterLearnerRegistrationSignal: null,
    totalLearnerRegistrationSignal: null,
    quarterCourseRegistrationSignal: null,
    totalCourseRegistrationSignal: null,
    quarterCourseCertificateSignal: null,
    totalCourseCertificateSignal: null,
    topOrganizationsSignal: null,
    topDomainsSignal: null,
    loadingQuarterLearnerRegistrationSignal: false,
    loadingTotalLearnerRegistrationSignal: false,
    loadingQuarterCourseRegistrationSignal: false,
    loadingTotalCourseRegistrationSignal: false,
    loadingQuarterCourseCertificateSignal: false,
    loadingTotalCourseCertificateSignal: false,
    loadingTopOrganizationsSignal: false,
    loadingTopDomainsSignal: false,
  };