import { Component, effect } from '@angular/core';
import { MRTGridTemplateComponent } from '@mrt/mrt-grid-template';
import { GridOptions } from '@mrt/mrt-grid-template/lib/interfaces/grid-options';
import { LookupsService } from '../../api/services/lookups/lookups.service';
import { MatTableDataSource } from '@angular/material/table';
import { ReportsService } from '../../api/services/reports/reports.service';
import { Sort } from '@angular/material/sort';

@Component({
  selector: 'app-organization',
  standalone: true,
  templateUrl: './organization.component.html',
  styleUrl: './organization.component.scss',
  imports: [
    MRTGridTemplateComponent,
  ],
})
export class OrganizationComponent {
  defaultSortColumn: string = 'createDate';
  defaultSortDirection: string = 'DESCENDING';
  paramsReq: any;
  gridOptions: GridOptions = {
    filterData: [
      { name: 'From', formControlName: 'fromDate', type: 'date', dateOffset: -365 },
      { name: 'To', formControlName: 'toDate', type: 'date', dateOffset: 0 },
      { name: 'Search', formControlName: 'search', type: 'search' },
      { name: 'Industry', formControlName: 'industryId', type: 'dropdown', lookup: this.lookupsService.industriesLookup },
      { name: 'State', formControlName: 'stateId', type: 'dropdown', lookup: this.lookupsService.statesLookup },
      { name: 'Status', formControlName: 'status', type: 'dropdown', lookup: this.lookupsService.entityStatusesLookup },
    ],
    openFilters: true,
    columns: [
      { header: 'Create Date', controlName: 'createDate', displayPipe: 'date' },
      { header: 'Organization Name', controlName: 'organizationName' },
      { header: 'Industry', controlName: 'orgIndustry'},
      { header: 'Org.Admin', controlName: 'orgAdmin' },
      { header: 'State', controlName: 'state' },
      { header: 'Active Users', controlName: 'numberOfActiveUsers' },
      { header: 'Certificates', controlName: 'planUnits' },
      { header: 'Status', controlName: 'status' },
    ],
    displaySearch: true,
    data: new MatTableDataSource<any>(),
    totalNumberOfItems: 0,
    allowExportCSV: true,
    disableflag: false,
    isLoading: false,
    fetchData: false,
  };

  constructor (
    private lookupsService: LookupsService,
    private reportsService: ReportsService,
  ) {
    effect(() => {
      this.gridOptions.isLoading = false;
      this.gridOptions.data.data = this.reportsService.organizationGrid().rows;
      this.gridOptions.totalNumberOfItems = this.reportsService.organizationGrid().totalNumberOfItems;
    });
  }

  ngOnInit() {
    this.gridOptions.fetchData = true; 
  }

  fetchAll(rawParams: any = {}) {
    this.gridOptions.isLoading = true;

    const { search, fromDate, toDate, stateId, status, industryId, sortOrder, pageSize, pageNumber } = rawParams;
    const fromDateISO = new Date(fromDate).toISOString();
    const toDateISO = new Date(toDate).toISOString();
    const newSortOrder = this.sortField(sortOrder);
    const params = {
      PageSize: pageSize ?? 20,
      PageNumber: pageNumber ?? 1,
      SearchTerm: search?.length! > 0 ? search : '',
      FromDate: fromDateISO ?? '',
      ToDate: toDateISO ?? '',
      StateId: stateId ? stateId : '',
      Status: status ? status : '',
      IndustryId: industryId ? industryId : '',
      SortField: newSortOrder
        ? newSortOrder.active && newSortOrder.direction
          ? newSortOrder.active
          : this.defaultSortColumn
        : this.defaultSortColumn,
      SortDirection: newSortOrder ? newSortOrder.direction ?? this.defaultSortDirection : this.defaultSortDirection,
    };

    this.paramsReq = params;
    this.reportsService.getOrganizationGrid(params);
  }

  sortField(sort: Sort) {
    let activeSort: any = this.defaultSortColumn;
    let direction: any = this.defaultSortDirection;
    if (sort !== null && sort !== undefined) { 
      switch (true) {
        case sort.active === 'createDate':
          activeSort = 'createDate';
          break;
        case sort.active === 'organizationName':
          activeSort = 'orgName';
          break;
        case sort.active === 'orgIndustry':
          activeSort = 'orgIndustry';
          break;
        case sort.active === 'orgAdmin':
          activeSort = 'orgAdmin';
          break;
        case sort.active === 'state':
          activeSort = 'state';
          break;
        case sort.active === 'numberOfActiveUsers':
          activeSort = 'numberOfActiveUsers';
          break;
        case sort.active === 'planUnits':
          activeSort = 'planUnits';
          break;
        case sort.active === 'status':
          activeSort = 'status';
          break;
      }
      switch (true) {
        case sort.direction === 'desc':
          direction = 'DESCENDING';
          break;
        case sort.direction === 'asc':
          direction = 'ASCENDING';
          break;
        case sort.direction === '':
          direction = null;
          break;
      }
    }
    return { active: activeSort, direction: direction };
  }

  actionEvent(event: any) {
    if (event.action === 'fetchAll') {
      this.fetchAll(event.data);
    } else if (event.action === 'exportCSV') {
      // this.exportCsv();
    } else if (event.action === 'formChanges') {
      // this.formChanges(event.data);
    } else if (event.action === 'clearFilters') {
      // this.clearFilters();
    } // 'edit' is specific to this component 
    else if (event.action === 'edit') {
      // this.openModal(event.data);
    }
  }
}