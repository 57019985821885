import { MatIconModule } from '@angular/material/icon';
import { NgClass, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AuthService } from '../../../../api/services/auth.service';
import { catchError } from 'rxjs';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { UserAuthService } from '../../../../store/user/user-auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  standalone: true,
  imports: [
    NgIf,
    RouterLink,
    MatFormFieldModule,
    ReactiveFormsModule,
    NgClass,
    MatInputModule,
    MatIconModule,
    MatSnackBarModule,
  ],
})
export class LoginComponent implements OnInit {
  form: FormGroup = new FormGroup({});
  hide = true;
  disabled = false;
  disableFlag = false;
  loginSuccessful = false;
  is2faSetup = true;
  qrCode: any; 

  is2FaRequired: boolean = true;

  downloadApp: boolean = true;
  setupVerifyAuthCode: boolean = false;
  verifyAuthCode: boolean = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private userService: UserAuthService,
    private snackBar: MatSnackBar,
    private router: Router,
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      email: this.fb.control(null, [Validators.required, Validators.email]),
      password: this.fb.control(null, Validators.required),
      verificationCode: this.fb.control(null),
    });
  }

  onSubmit() {
    if (this.form.valid) {
      this.disabled = true;
      this.authService
        .loginValidation(this.form.value.email, this.form.value.password)
        .pipe(
          catchError((error) => {
            this.disabled = false;
            this.snackBar.open('Invalid username or password', 'Close', { duration: 3000 });
            return error;
          }),
        )
        .subscribe((image: any) => {
          this.qrCode = new Blob([image], { type: 'image/bitmap' });
          if (this.qrCode.size > 0) {
            this.is2faSetup = false;
            this.disabled = false;
            this.loginSuccessful = true;

            this.downloadApp = true;
          }  else {
            this.is2faSetup = true;
            this.verifyAuthCode = true;
            this.disabled = false;
            this.loginSuccessful = true;
            this.form.get('verificationCode')?.setValidators(Validators.required);
          }
        });
    }
  }

  onSubmit2fa() {
    if (this.form.valid) {
      this.disabled = true;
      this.userService
        .login(this.form.value.email, this.form.value.password, this.form.value.verificationCode)
        .pipe(
          catchError((error) => {
            this.disabled = false;
            this.snackBar.open('Invalid 2FA code', 'Close', { duration: 3000 });
            return error;
          }),
        )
        .subscribe(() => {
          this.disabled = false;
          this.snackBar.open('Login successful', 'Close', { duration: 3000 });
          this.router.navigate(['/dashboard']);
        });
    }
  }



  showQRCode() {
    this.downloadApp = false;
    this.setupVerifyAuthCode = true;

    if (this.qrCode.size > 0) {
      this.is2faSetup = false;
      const reader = new FileReader();
      reader.readAsDataURL(this.qrCode);
      reader.onloadend = () => {
        const dataUrl = reader.result;
        const img = document.createElement('img');
        img.src = dataUrl as string;
        document.getElementById('qrCode')?.appendChild(img);
      };
    }
  }

  showVerifyAuthCode() {
    this.setupVerifyAuthCode = false;
    this.verifyAuthCode = true;
  }
}
