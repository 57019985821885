import { Injectable, computed, inject, signal } from "@angular/core";
import { BaseService } from "../base.service";
import { OrganizationStore } from "./organization.store";


@Injectable({
  providedIn: 'root'
})
export class OrganizationsService extends BaseService {
    organizationStore = inject(OrganizationStore);

    //Organization Grid
    getOrganizationGrid(params: any) {
        this.organizationStore.setLoading(true);

        return this.http.get('Report/organization-grid', {params: params})
          .subscribe((organizationGrid: any) => {
            this.organizationStore.setAll(organizationGrid);
            this.organizationStore.setLoading(false);
          });
    }
}