import { Component, Input, OnDestroy, Inject, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT, NgIf, NgClass } from '@angular/common';
import { SpinnerService } from '../../../services/spinner.service';
import { filter, skip, take, takeUntil } from 'rxjs/operators';
import { Subject, timer } from 'rxjs';

@Component({
    selector: 'app-spinner',
    template: ` 
    <div class="preloader" 
        *ngIf="isSpinnerVisible" 
        [ngClass]="spinnerHasWhiteBackground ? 'white-bg' : 'overlay-bg'">
      <div class="spinner">
        <div class="double-bounce1"></div>
        <div class="double-bounce2"></div>
      </div>
  </div>`,
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgIf, NgClass],
})
export class SpinnerComponent implements OnDestroy {
  public isSpinnerVisible = false;
  public spinnerHasWhiteBackground = true;
  private onDestroy$ = new Subject<void>();
  private changeVisibilityValueToChange: boolean | undefined;
  private changeBackgroundValueToChange: boolean = true;
  private isWaiting = false;

  @Input() public backgroundColor = 'rgba(0, 115, 170, 0.69)';

  constructor(
    private router: Router,
    private spinnerService: SpinnerService,
    @Inject(DOCUMENT) private document: Document
  ) {

    this.spinnerService.showSpinner.pipe(skip(1)).subscribe((spinnerVisible) => {
      if (spinnerVisible !== this.isSpinnerVisible) {
        if (!this.isWaiting) {
          this.isSpinnerVisible = spinnerVisible;
          this.isWaiting = true;
    
          timer(500)
            .pipe(
              take(1),
              takeUntil(this.onDestroy$)
            )
            .subscribe(() => {
              const nextValue = this.changeVisibilityValueToChange;
              if (nextValue !== undefined) {
                this.isSpinnerVisible = nextValue;
                this.changeVisibilityValueToChange = undefined;
              }
              this.isWaiting = false;
            });
        } else {
          this.changeVisibilityValueToChange = spinnerVisible;
        }
      } else {
        this.changeVisibilityValueToChange = spinnerVisible;
      }

      if (!this.isSpinnerVisible) {
        this.spinnerHasWhiteBackground = this.changeBackgroundValueToChange;
      }
    });
    this.spinnerService.spinnerBackground.pipe(skip(1)).subscribe((spinnerHasWhiteBackground) => {
      this.changeBackgroundValueToChange = spinnerHasWhiteBackground;
      if (!this.isSpinnerVisible) {
        this.spinnerHasWhiteBackground = spinnerHasWhiteBackground;
      }
    });

  }

  ngOnDestroy(): void {
    this.isSpinnerVisible = false;
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
