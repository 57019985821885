import {
    patchState,
    signalStore,
    withMethods,
    withState,
  } from '@ngrx/signals';
  import { QuarterlyReports, quarterlyReportsInitialState } from './quarterly-reports.model';
  
export const QuarterlyReportsStore = signalStore(
  withState(quarterlyReportsInitialState),

  withMethods(({...store}) => ({
      setQuarterLearnerRegistration(quarterLearnerRegistrationSignal: QuarterlyReports["quarterLearnerRegistrationSignal"]) {
        patchState(store, {quarterLearnerRegistrationSignal});
      },
      setTotalLearnerRegistration(totalLearnerRegistrationSignal: QuarterlyReports["totalLearnerRegistrationSignal"]) {
        patchState(store, {totalLearnerRegistrationSignal});
      },
      setQuarterCourseRegistration(quarterCourseRegistrationSignal: QuarterlyReports["quarterCourseRegistrationSignal"]) {
        patchState(store, {quarterCourseRegistrationSignal});
      },
      setTotalCourseRegistration(totalCourseRegistrationSignal: QuarterlyReports["totalCourseRegistrationSignal"]) {
        patchState(store, {totalCourseRegistrationSignal});
      },
      setQuarterCourseCertificate(quarterCourseCertificateSignal: QuarterlyReports["quarterCourseCertificateSignal"]) {
        patchState(store, {quarterCourseCertificateSignal});
      },
      setTotalCourseCertificate(totalCourseCertificateSignal: QuarterlyReports["totalCourseCertificateSignal"]) {
        patchState(store, {totalCourseCertificateSignal});
      },
      setTopOrganizations(topOrganizationsSignal: QuarterlyReports["topOrganizationsSignal"]) {
        patchState(store, {topOrganizationsSignal});
      },
      setTopDomains(topDomainsSignal: QuarterlyReports["topDomainsSignal"]) {
        patchState(store, {topDomainsSignal});
      },
      setQuarterLearnerRegistrationLoading(isLoading: boolean) {
        patchState(store, {loadingQuarterLearnerRegistrationSignal: isLoading});
      },
      setTotalLearnerRegistrationLoading(isLoading: boolean) {
        patchState(store, {loadingTotalLearnerRegistrationSignal: isLoading});
      },
      setQuarterCourseRegistrationLoading(isLoading: boolean) {
        patchState(store, {loadingQuarterCourseRegistrationSignal: isLoading});
      },
      setTotalCourseRegistrationLoading(isLoading: boolean) {
        patchState(store, {loadingTotalCourseRegistrationSignal: isLoading});
      },
      setQuarterCourseCertificateLoading(isLoading: boolean) {
        patchState(store, {loadingQuarterCourseCertificateSignal: isLoading});
      },
      setTotalCourseCertificateLoading(isLoading: boolean) {
        patchState(store, {loadingTotalCourseCertificateSignal: isLoading});
      },
      setTopOrganizationsLoading(isLoading: boolean) {
        patchState(store, {loadingTopOrganizationsSignal: isLoading});
      },
      setTopDomainsLoading(isLoading: boolean) {
        patchState(store, {loadingTopDomainsSignal: isLoading});
      },
      resetStore() {
        patchState(store, quarterlyReportsInitialState );
      }
  }))
);