import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { FooterComponent } from '../footer/footer.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-blank',
  templateUrl: './blank.component.html',
  styleUrls: [],
  standalone: true,
  imports: [NgIf, MatToolbarModule, MatSidenavModule, RouterOutlet, FooterComponent],
})
export class AppBlankComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}