import {components} from "../../../../api/interfaces/swagger-types";
export type LearnerMetrics = components["schemas"]["LearnerMetricsReportViewModel"]

export interface LearnerReports {
    stateLearnersGridSignal: LearnerMetrics | null;
    learnerMetricsSignal: LearnerMetrics | null;
    stateLearnersGridLoadingSignal: boolean;
    learnerMetricsLoadingSignal: boolean;
  }
  
  export const learnerReportsInitialState: LearnerReports = {
    stateLearnersGridSignal: null,
    learnerMetricsSignal: null,
    stateLearnersGridLoadingSignal: false,
    learnerMetricsLoadingSignal: false,
  }


