import { Component } from '@angular/core';

@Component({
  selector: 'app-training-report',
  standalone: true,
  imports: [],
  templateUrl: './training-report.component.html',
  styleUrl: './training-report.component.scss'
})
export class TrainingReportComponent {

}
