import { Component } from '@angular/core';

@Component({
  selector: 'app-grid-filter-icon',
  standalone: true,
  imports: [],
  templateUrl: './filter-icon.component.html',
  styleUrl: './filter-icon.component.scss'
})
export class GridFilterIconComponent {

}
