import {
    patchState,
    signalStore,
    withMethods,
    withState,
  } from '@ngrx/signals';
  import { Domains, initialState } from './domains.model';
  
  export const DomainStore = signalStore(
    withState(initialState),
  
    withMethods(({...store}) => ({
      setAll(domainsSignal: Domains["domainsSignal"]) {
        patchState(store, {domainsSignal});
      },
      setLoading(isLoading: boolean) {
        patchState(store, {loadingSignal: isLoading});
      },
      resetStore() {
        patchState(store, initialState);
      }
    }))
  );