import { Component, effect, inject } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { GridOptions } from '@mrt/mrt-grid-template/lib/interfaces/grid-options';
import { LookupsService } from '../../../api/services/lookups/lookups.service';
import { LookupsStore } from '../../../api/services/lookups/lookups.store';
import { ReportsService } from '../../../api/services/reports/reports.service';
import { MRTGridTemplateComponent } from '@mrt/mrt-grid-template';
import { LearnerReportsStore } from '../../../api/services/reports/learner-reports/learner-reports.store';

@Component({
  selector: 'app-learner-report',
  standalone: true,
  templateUrl: './learner-report.component.html',
  styleUrl: './learner-report.component.scss',
  imports: [
    MRTGridTemplateComponent,
  ]
})
export class LearnerReportComponent {
  readonly lookupsStore = inject(LookupsStore);
  readonly learnerReportsStore = inject(LearnerReportsStore);
  
  defaultSortColumn: string = 'dateAdded';
  defaultSortDirection: string = 'DESCENDING';
  paramsReq: any;
  gridOptions: GridOptions = {
    filterData: [
      { name: 'From', formControlName: 'fromDate', type: 'date', dateOffset: -1 },
      { name: 'To', formControlName: 'toDate', type: 'date', dateOffset: 0 },
      { name: 'Search', formControlName: 'search', type: 'search' },
      { name: 'Training', formControlName: 'trainingId', type: 'dropdown', lookup: this.lookupsStore.trainingsSignal },
      { name: 'Course', formControlName: 'courseId', type: 'dropdown', lookup: this.lookupsStore.coursesSignal },
      { name: 'State', formControlName: 'stateId', type: 'dropdown', lookup: this.lookupsStore.statesSignal },
      { name: 'Status', formControlName: 'status', type: 'dropdown', lookup: this.lookupsStore.courseStatusSignal },
    ],
    openFilters: true,
    columns: [
      { header: 'First Name', controlName: 'firstName' },
      { header: 'Last Name', controlName: 'lastName' },
      { header: 'Email Address', controlName: 'email'},
      { header: 'Training', controlName: 'trainingType' },
      { header: 'Course', controlName: 'training' },
      { header: 'Added', controlName: 'dateAdded', displayPipe: 'date' },
      { header: 'End', controlName: 'dateCompleted', displayPipe: 'date' },
      { header: 'Status', controlName: 'trainingStatus' },
      { header: 'Total Time', controlName: 'totalTime' },
      { header: 'Certificate', controlName: 'certificateName',
        actionColumnitems: [
          { 
            buttonAction: 'download', disableOnColumnValue: { column: 'certificateName', values: [null]},
            text: { calculateText: (certificateName: any) => { return certificateName ? certificateName: '-'; }, textColumn: 'certificateName' },
            buttonClass: (certificateName: string) => certificateName === 'Action Required' ? 'btn-warning' : 'btn-default'
          },
        ]
      },
    ],
    displaySearch: true,
    data: new MatTableDataSource<any>(),
    totalNumberOfItems: 0,
    allowExportCSV: true,
    disableflag: false,
    isLoading: false,
    fetchData: false,
  };

  constructor(
    private lookupsService: LookupsService,
    private reportsService: ReportsService
  ) {
    effect(() => {
      this.gridOptions.isLoading = false;
      this.gridOptions.data.data = this.learnerReportsStore.learnerMetricsSignal()?.rows ?? [];
      this.gridOptions.totalNumberOfItems = this.learnerReportsStore.learnerMetricsSignal()?.totalNumberOfItems ?? 0;
    });
  }

  ngOnInit() {
    this.lookupsService.getTrainings();
    this.lookupsService.getCourses();
    this.lookupsService.getStates();
    this.lookupsService.getCourseStatuses();
    this.gridOptions.fetchData = true;
  }

  fetchAll(rawParams?: any) {
    this.gridOptions.isLoading = true;

    const { search, fromDate, toDate, stateId, trainingIds, courseIds, status, orgId, sortOrder, pageSize, pageNumber } = rawParams;
    const newSortOrder = this.sortField(sortOrder);
    const fromDateISO = new Date(fromDate).toISOString();
    const toDateISO = new Date(toDate).toISOString();
    const params = {
      PageSize: pageSize ?? 20,
      PageNumber: pageNumber ?? 1,
      SearchTerm: search?.length! > 0 ? search : '',
      FromDate: fromDateISO ?? '',
      ToDate: toDateISO ?? '',
      StateId: stateId ? stateId : '',
      OrgId: orgId ? orgId : '',
      TrainingIds: trainingIds ? trainingIds : [],
      CourseIds: courseIds ? courseIds : [],
      Status: status ? status : '',
      SortField: newSortOrder
        ? newSortOrder.active && newSortOrder.direction
          ? newSortOrder.active
          : this.defaultSortColumn
        : this.defaultSortColumn,
      SortDirection: newSortOrder ? newSortOrder.direction ?? this.defaultSortDirection : this.defaultSortDirection,
    };

    this.paramsReq = params;
    this.reportsService.getLearnerMetrics(params);

  }

  sortField(sort: Sort) {
    let activeSort: any = this.defaultSortColumn;
    let direction: any = this.defaultSortDirection;
    if (sort !== null && sort !== undefined) { 
      switch (true) {
        case sort.active === 'firstName':
          activeSort = 'firstName';
          break;
        case sort.active === 'lastName':
          activeSort = 'lastName';
          break;
        case sort.active === 'organizationName':
          activeSort = 'orgName';
          break;
        case sort.active === 'email':
          activeSort = 'email';
          break;
        case sort.active === 'state':
          activeSort = 'state';
          break;
        case sort.active === 'training':
          activeSort = 'training';
          break;
        case sort.active === 'course':
          activeSort = 'courseName';
          break;
        case sort.active === 'version':
          activeSort = 'version';
          break;
        case sort.active === 'dateAdded':
          activeSort = 'dateAdded';
          break;
        case sort.active === 'dateCompleted':
          activeSort = 'dateCompleted';
          break;
          case sort.active === 'purchaseDate':
            activeSort = 'purchaseDate';
            break;
          case sort.active === 'price':
            activeSort = 'amount';
            break;
        case sort.active === 'trainingStatus':
          activeSort = 'trainingStatus';
          break;
        case sort.active === 'totalTime':
          activeSort = 'totalTime';
          break;
        case sort.active === 'certificateName':
          activeSort = 'certificateName';
          break;
        case sort.active === 'shared':
          activeSort = 'shared';
          break;
      }
      switch (true) {
        case sort.direction === 'desc':
          direction = 'DESCENDING';
          break;
        case sort.direction === 'asc':
          direction = 'ASCENDING';
          break;
        case sort.direction === '':
          direction = null;
          break;
      }
    }
    return { active: activeSort, direction: direction };
  }

  actionEvent(event: any) {
    if (event.action === 'fetchAll') {
      this.fetchAll(event.data);
    } else if (event.action === 'exportCSV') {
      // this.exportCsv();
    } else if (event.action === 'formChanges') {
      // this.formChanges(event.data);
    } else if (event.action === 'clearFilters') {
      // this.clearFilters();
    } // 'edit' is specific to this component 
    else if (event.action === 'edit') {
      // this.openModal(event.data);
    }
  }
}
