<footer
  class="border-t border-color-ddd flex flex-col lg:flex-row text-primary items-start lg:items-center justify-start lg:justify-center copywrite p-3 pr-8">
  <div>
    <span> &copy; {{ date }} MRT State Reporting</span>
  </div>
  <div>
    <ng-container *ngIf="!loggedIn">
      <a href="https://mandatedreportertraining.com/company/terms-of-use" target="_blank"
        class="btn btn-link font-semibold"><span class="mx-1 hidden lg:inline-block font-normal">|</span>Terms Of
        Use<span class="mx-1 font-normal">|</span></a>
      <a href="https://mandatedreportertraining.com/company/privacy-statement" target="_blank"
        class="btn btn-link font-semibold">Privacy Statement<span class="mx-1 font-normal">|</span></a>
      <a href="https://mandatedreportertraining.com/company/accessibility" target="_blank"
        class="btn btn-link font-semibold">Accessibility</a>
    </ng-container>
  </div>
  <div>
    <span class="mx-1 hidden lg:inline-block">|</span>
    <span class="mr-1 w-full">Powered by <a href="https://simplelearning.com" target="_blank"
        class="btn btn-link font-semibold">Simple</a>
    </span>
  </div>
</footer>