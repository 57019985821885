<div class="w-screen blank-page-min-height bg-cover bg-no-repeat bg-center items-center flex pt-8 ">
  <div class="w-full flex justify-center h-full">
    <div class="">
      <div class="flex flex-col justify-center">
        <h1 class="text-primary  font-bold  sm:w-10/12 text-center leading-none mx-auto mb-3">
          <span class="block">Reset Password</span>
        </h1>
        <p class="text-center font-14 mb-10">Enter your new password.</p>
      </div>
  
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div>
          <mat-form-field class="w-full" hideRequiredMarker>
            <mat-label>Email address</mat-label>
            <input type="email" matInput formControlName="email" />
            <mat-error
              *ngIf="form.controls['email'].hasError('required') && form.controls['email'].touched"
              class="text-danger support-text"
              >You must include currrent password.
            </mat-error>
            <mat-error
              *ngIf="form.controls['email'].hasError('email')  && form.controls['email'].touched"
              class="text-danger support-text"
              >You must include a valid email address.</mat-error
            >
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="w-full" [ngClass]="{ 'mb-20 sm:mb-9': form.controls['newPassword'].hasError('message')  }" hideRequiredMarker>
            <mat-label>Password</mat-label>
            <input [type]="hide ? 'password' : 'text'" matInput formControlName="newPassword" />
            <mat-icon matSuffix class="cursor-default" (click)="hide = !hide">{{
              hide ? 'visibility' : 'visibility_off'
            }}</mat-icon>

            <mat-error
              *ngIf="form.controls['newPassword'].hasError('required') && form.controls['newPassword'].touched"
              class="text-danger support-text"
              >You must include password.
            </mat-error>
          </mat-form-field>
        </div>
        <div [ngClass]="{ 'mb-4 -mt-12 text-sm': form.controls['newPassword'].hasError('message') }">
          <mat-error class="text-danger support-text" *ngIf="form.controls['newPassword'].hasError('message') ">{{
            form.controls['newPassword'].getError('message')
          }}</mat-error>
        </div>
        <div>
          <mat-form-field class="w-full" hideRequiredMarker>
            <mat-label>Confirm Password</mat-label>
            <input [type]="chide ? 'password' : 'text'" matInput formControlName="confirmPassword" appBlockCopyPaste />
            <mat-icon matSuffix class="cursor-default" (click)="chide = !chide">{{
              chide ? 'visibility' : 'visibility_off'
            }}</mat-icon>

            <mat-error
              *ngIf="form.controls['confirmPassword'].hasError('required') && form.controls['confirmPassword'].touched"
              class="text-danger support-text"
              >You must include confirm password.
            </mat-error>
            <mat-error *ngIf="form.controls['confirmPassword']?.hasError('mustMatch')" class="text-danger font-14"
              >Passwords do not match.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="mt-2 text-center">
          <button type="submit" class="btn-primary large" [disabled]="disableFlag">Reset</button>
        </div>
      </form>
    </div>
  </div>
</div>
  