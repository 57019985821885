import { Component, effect, inject } from '@angular/core';
import { NgIf, NgFor, NgClass, CommonModule } from '@angular/common';
import { UntypedFormBuilder, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { LookupsService } from '../../../api/services/lookups/lookups.service';
import { LookupsStore } from '../../../api/services/lookups/lookups.store';
import { NgApexchartsModule, ApexOptions } from 'ng-apexcharts';
import { RouterModule } from '@angular/router';
import { QuarterlyReportsStore } from '../../../api/services/reports/quarterly-reports/quarterly-reports.store';
import { SnackbarComponent, ToastType } from '../../../shared/components/layout/snackbar/snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ReportsService } from '../../../api/services/reports/reports.service';

@Component({
  selector: 'app-quarterly-report',
  standalone: true,
  templateUrl: './quarterly-report.component.html',
  styleUrl: './quarterly-report.component.scss',
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatOptionModule,
    MatSelectModule,
    MatDatepickerModule,
    MatInputModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatNativeDateModule,
    MatRippleModule,
    NgIf,
    NgFor,
    NgClass,
    CommonModule,
    NgApexchartsModule,
    RouterModule
  ]
})


export class QuarterlyReportComponent  {
  readonly lookupsStore = inject(LookupsStore);
  readonly quarterlyReportsStore = inject(QuarterlyReportsStore); 

  yearQuarters:any[] = [];
  filtersForm!: UntypedFormGroup;
  chartOptions: any = {};
  monthlyTrainingMetrics: any = {};
  chartSeries: number[] = [];
  pieLabels: string[] = [];
  chartWidth = 1200;

  allStates: any[] = [];

  reportingPeriod: string = '';
  currentState: string = '';

  constructor(
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private lookupsService: LookupsService,
    private reportsService: ReportsService
  ) {
    this.lookupsService.getStates();
    var yearQuarters:any[] = [];
    var year = new Date().getFullYear();
    var quarter = Math.ceil((new Date().getMonth() + 1) / 3);
    for (var i = year; i >= 2018; i--) {
      for (var j = 4; j >= 1; j--) {
        if (i === year && j > quarter) {
          continue;
        }
        yearQuarters.push({ value: `${i}Q${j}`, label: `${i} - Quarter ${j}` });
      }
    }
    this.yearQuarters = yearQuarters;
    this.filtersForm = this.fb.group({
      yearQuarter: [yearQuarters[1].value],
      state: [null]
    });

    this.reportingPeriod =  yearQuarters[1].value.replace(/(\d{4})Q(\d)/, '$1 Quarter $2 Report');
    this.currentState = this.filtersForm.get('state')?.value ?? '';
    effect(() => {
      if (this.lookupsStore.statesSignal().length == 1) {
        this.allStates = this.lookupsStore.statesSignal();
        this.filtersForm.patchValue({ state: this.allStates[0].text });
        this.currentState = this.filtersForm.get('state')?.value;
      }
    });

    this.fetchData();
    effect(() => {
      if (this.quarterlyReportsStore.quarterCourseCertificateSignal() !== null && this.quarterlyReportsStore.quarterCourseRegistrationSignal() !== null) {
        this.chartSeries = [];
        this.pieLabels = [];
        this.quarterlyReportsStore.quarterCourseCertificateSignal().courseTally.forEach((item: any) => {
          this.pieLabels.push(`${item.description1.name} (${this.formatNumber(item.count1.total)})`);
          this.chartSeries.push(item.count1.total);
        });

        this.chartOptions = {
            chart: {
              type: 'pie',
              width: '100%',
              height: '100%', 
            },
            labels: this.pieLabels,
            colors: ["#000033", "#29B494", "#C1C1C1", "#FF0F0F", "#595959", "#FDC602", "#7BAAF7", "#D10CE8", "#F07B72", "#FCD04F"]
        };
  
        var displayMonths: string[] = [];
        var monthlyRegistrations: number[] = [];
        var monthlyCertificates: number[] = [];

        this.quarterlyReportsStore.quarterCourseCertificateSignal().monthTally.forEach((item: any) => {
          displayMonths.push(item.description1.name);
          monthlyCertificates.push(item.count1.total);
        });
        this.quarterlyReportsStore.quarterCourseRegistrationSignal().monthTally.forEach((item: any) => {
          monthlyRegistrations.push(item.count1.total);
        });
        this.monthlyTrainingMetrics = {
          series: [
            {
              name: "Registrations",
              data: monthlyRegistrations
            },
            {
              name: "Certificates",
              data: monthlyCertificates
            }
          ],
          chart: {
            type: "bar",
            height: 400,
            toolbar: {
              show: true
            },
          },
          dataLabels: {
            formatter: (val) => {
              return this.formatNumber(+val);
            }
          },
          plotOptions: {
            bar: {
              horizontal: false
            }
          },
          yaxis: {
            labels: {
              formatter: (val) => {
                return this.formatNumber(+val);
              }
            }
          },
          xaxis: {
            type: "category",
            categories: displayMonths
          },
          tooltip: {
            y: {
              formatter: (val) => {
                return this.formatNumber(+val);
              }
            }
          },
          colors: ["#000033", "#29B494", "#FF0F0F", "#595959", "#FDC602", "#7BAAF7", "#D10CE8", "#F07B72", "#FCD04F"],
          legend: {
            position: "top",
            horizontalAlign: "left",
            offsetX: 40
          }
        };
      }
    });
 

    this.filtersForm.valueChanges.subscribe((data) => {

      const selectedStateValue = this.filtersForm.get('state')?.value;
      const statesLookup = this.lookupsStore.statesSignal();

      const matchingState = statesLookup.find(state => state['text'] === selectedStateValue);
      this.currentState = matchingState ? matchingState.label : '';

      this.fetchData();
      this.currentState = this.currentState ?? '';
      this.reportingPeriod = data.yearQuarter.replace(/(\d{4})Q(\d)/, '$1 Quarter $2 Report');
    });
  }
  
  fetchData() {
    const rawParams = this.filtersForm.getRawValue();
    const params = {
      Year: rawParams.yearQuarter.split('Q')[0],
      Quarter: rawParams.yearQuarter.split('Q')[1],
      State: rawParams.state ?? '',
      AllTime: false
    };
    const paramsAllTime = {
      Year: rawParams.yearQuarter.split('Q')[0],
      Quarter: rawParams.yearQuarter.split('Q')[1],
      State: rawParams.state ?? '',
      AllTime: true
    }

    this.reportsService.getTotalLearnerRegistration(paramsAllTime);
    this.reportsService.getTotalCourseRegistration(paramsAllTime);
    this.reportsService.getTotalCourseCertificate(paramsAllTime);

    this.reportsService.getQuarterLearnerRegistration(params);
    this.reportsService.getQuarterCourseRegistration(params);
    this.reportsService.getQuarterCourseCertificate(params);
    this.reportsService.getTopOrganizations(params);
    this.reportsService.getTopDomains(params);
  }


  downloadReport() {
    const rawParams = this.filtersForm.getRawValue();
    const params = {
      Year: rawParams.yearQuarter.split('Q')[0],
      Quarter: rawParams.yearQuarter.split('Q')[1],
      State: rawParams.state ?? null
    };
    // this.quarterlyReportsStore.downloadQuarterlyReport(params);
    if (params.State === null) {
      this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: `A state must be selected to export to PDF` }})
      return;
    }
    this.reportsService.downloadQuarterlyReport(params);
  }

  formatNumber(value: number): string {
    if (value == null) {
      return '';
    }
    const roundedValue = Math.round(value);
    return `${roundedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
  }
}
