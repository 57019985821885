import {
  patchState,
  signalStore,
  withMethods,
  withState,
} from '@ngrx/signals';
import { Organization, initialState } from './organization.model';

export const OrganizationStore = signalStore(
  withState(initialState),

  withMethods(({...store}) => ({
    setAll(organizationSignal: Organization["organizationSignal"]) {
      patchState(store, {organizationSignal});
    },
    setLoading(isLoading: boolean) {
      patchState(store, {loadingSignal: isLoading});
    },
    resetStore() {
      patchState(store, initialState);
    }
  }))
);