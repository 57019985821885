import { Injectable } from '@angular/core';
import { UserAuthService } from '../../../../store/user/user-auth.service';



export interface BadgeItem {
  type: string;
  value: string;
}

export interface Separator {
  name: string;
  type?: string;
}

export interface SubChildren {
  state: string;
  name: string;
  type?: string;
}

export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
  requiredPermission?: Permissions;
  child?: SubChildren[];
  special?: string;
}

export interface Menu {
    state?: string | null;
    name: string;
    type: string;
    icon: string;
    expandOnLoad?: boolean;
    badge?: BadgeItem[];
    separator?: Separator[];
    children?: ChildrenItems[];
    requiredPermission?: Permissions;
    onlyRole?: string;
}

export const MENUITEMS: Menu[] = [
  // {
  //   state: 'dashboard',
  //   name: 'Dashboard',
  //   type: 'link',
  //   icon: 'dashboard', 
  // },
  {
    state: 'organizations',
    name: 'Organizations',
    type: 'link',
    icon: 'business',   
  },
  {
    state: 'domains',
    name: 'Domains',
    type: 'link',
    icon: 'school',    
  },
  {
    state: 'report',
    name: 'Reports',
    type: 'sub',   
    icon: 'insert_chart',  
    children: [
      { state: 'learner', name: 'Learner Report', type: 'link' },
      { state: 'training', name: 'Training Report', type: 'link' },
      // { state: 'interaction', name: 'Interaction Report', type: 'link' },
      { state: 'quarterly', name: 'Quarterly Report', type: 'link' }
    ]
  },
  {
    state: 'partners',
    name: 'Partners',
    type: 'link',
    icon: 'supervisor_account',
    onlyRole: 'Admin'
  }
];


@Injectable({ providedIn: 'root' })
export class MenuItems {
  constructor(
    private userAuthService: UserAuthService
  ) {}
  getMenuitem() {
    return MENUITEMS.filter((item: Menu) => {
      if (item.onlyRole) {
        return this.userAuthService.user$().roles.includes(item.onlyRole);
      }
      return true;
    });
  }
}
