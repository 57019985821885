import { CanActivateFn, Router } from '@angular/router';
import { UserAuthService } from '../../store/user/user-auth.service';
import { inject } from '@angular/core';

export const roleGuard: CanActivateFn = (route, state) => {
  const userService: UserAuthService = inject(UserAuthService);
  const onlyRole: string = route.data['permissions'].only;
  const redirectTo: string = route.data['permissions'].redirectTo;
  
  if (onlyRole) {
    return userService.userHasRole(onlyRole);
  }

  if (redirectTo) {
    return new Router().createUrlTree([redirectTo]);
  }
  
  return true;
};
