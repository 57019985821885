import { CanActivateFn, Router } from '@angular/router';
import { UserAuthService } from '../../store/user/user-auth.service';
import { inject } from '@angular/core';

export const authGuard: CanActivateFn = (route, state) => {
  const userService: UserAuthService = inject(UserAuthService);

  if (!userService.isLoggedIn) {
    return new Router().createUrlTree(['/login']);
  }

  return true;
};
