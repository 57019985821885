<div class="reg-containers site-container-1000">
  <div class="flex justify-between flex-col md:flex-row pt-8">
    <div class="flex-1">
      @if (!loginSuccessful) {
      <h1 class="text-primary font-bold mb-5 text-center">Log In</h1>
      <form [formGroup]="form" class="lg:w-1/2 mx-auto">
        <div>
          <mat-form-field class="w-full">
            <mat-label>Email Address</mat-label>
            <input matInput type="email" formControlName="email" />
            <mat-error *ngIf="form.controls['email'].errors?.['email'] && form.controls['email'].touched">You must
              include a valid email.
            </mat-error>
          </mat-form-field>
          <div [ngClass]="{ 'mb-1 -mt-4 ': form.controls['email'].errors?.['invite'] }">
            <!-- <mat-error class="text-danger support-text" *ngIf="form.controls['userName'].errors?.['invite']">
                {{ form.controls['userName'].getError('invite').message }}
                <br />
                <span
                  class="underline cursor-pointer"
                  (click)="resendInvite(form.controls['userName'].getError('invite').email)"
                  >Resend Invite Email</span
                >
              </mat-error> -->
          </div>
        </div>
        <div>
          <mat-form-field class="w-full pb-0">
            <mat-label>Password</mat-label>
            <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" />
            <mat-icon matSuffix class="cursor-default eye-hidden" (click)="hide = !hide">{{
              hide ? 'visibility' : 'visibility_off'
              }}</mat-icon>
            <mat-error>Must be a valid Password</mat-error>
          </mat-form-field>
        </div>
        <div class="text-center mt-4">
          <button type="submit" (click)="onSubmit()" [disabled]="disabled" class="btn-primary large">
            Log In
          </button>
        </div>
      
        <div class="text-center mt-8"> 
            <a routerLink="/forgot-password" class="capitalize font-semibold btn btn-link underline">forgot password?</a>
          </div>  
      </form>
      } @else {


      @if (!is2faSetup) {   

              <div *ngIf="downloadApp;">
                <div class="text-center login-container">
                  <h1>Two-Factor Authentication</h1>
                  <p class="mt-3">Your organization requires additional authentication to keep your account secure.
                  </p>
                  <h2 class="learner mt-4 strong">Download an Authenticator App</h2>
                  <p>On your phone, download and install an Authenticator App.</p>
                  <p class="mt-3 flex items-center justify-center">Google Authenticator <a
                      href="https://apps.apple.com/us/app/google-authenticator/id388497605"
                      target="_blank"><mat-icon>apple</mat-icon><span class="sr-only"></span></a> <a
                      href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_US&gl=US&pli=1"
                      target="_blank"><mat-icon>android</mat-icon><span class="sr-only"></span></a></p>
                  <p class="flex items-center justify-center">Microsoft Authenticator <a
                      href="https://apps.apple.com/us/app/microsoft-authenticator/id983156458"
                      target="_blank"><mat-icon>apple</mat-icon><span class="sr-only"></span></a> <a
                      href="https://play.google.com/store/apps/details?id=com.azure.authenticator&hl=en_US&gl=US"
                      target="_blank"><mat-icon>android</mat-icon><span class="sr-only"></span></a></p>
                  <p class="mt-3">Once you've installed the Authenticator App, click Next.</p>
                  <div class="my-5">
                    <button class="btn-primary large" (click)="showQRCode()">Next</button>
                  </div>
                </div>
              </div>

              <div *ngIf="setupVerifyAuthCode;">
                <div class="login-container">
                  <div class="h-full text-center">
                   
                    <h1>Two-Factor Authentication</h1>
                    <p class="mt-3">Mandated Reporter Training requires additional authentication to keep your account
                      secure.</p>
                    <h2 class="learner mt-10 strong">Scan the QR Code</h2>
                    <p class="width25 mx-auto">Open the Authenticator App, follow instructions to add a new account and
                      use the app to scan the QR Code below.</p>

                    <div class="flex justify-center mt-8">
                      <div id="qrCode" class="w-[200px]"></div>
                    </div>
                  
                   
                    <div class="mt-8">
                      <button (click)="showVerifyAuthCode()" class="btn-primary large">
                        Verify
                      </button>
                    </div>
                  </div>
                </div>
              </div>

        


      
      }
      <div class="login-container" *ngIf="verifyAuthCode;">
        <div class="h-full text-center">
          <h1>Two-Factor Authentication</h1>
          <p class="mt-3">Mandated Reporter Training requires additional authentication to keep your account secure.
          </p>
          <h2 class="learner mt-10 strong">Enter Authentication Code</h2>
          <p class="width25 mx-auto">Open your Authenticator App and enter the 6-digit code below.</p>
          <form class="flex flex-col items-center mt-8" [formGroup]="form">
            <div class="width25">
              <mat-form-field class="flex mx-auto">
                <mat-label>Verification Code</mat-label>
                <input matInput type="text" class="w-full" formControlName="verificationCode" />
                <mat-error
                  *ngIf="form.controls['verificationCode'].errors && form.controls['verificationCode'].touched">
                  That code didn't work
                </mat-error>
              </mat-form-field>
              <button type="submit" (click)="onSubmit2fa()" [disabled]="disableFlag" class="btn-primary large mt-8">
                Verify
              </button>
            </div>
          </form>
        </div>
      </div>
      }
    </div>
  </div>
</div>