@if (loading) {
  <div class="lds-facebook">
    <div></div>
    <div></div>
    <div></div>
  </div>
  } @else {

  
      @if (!regSuccessful) {


        
          <div class="site-container-1000 mt-8 reg-containers">
            <div class="w-full">
              <div class="w-full text-center">
                <h1>Create an Account</h1>
                <p>
                  Create an partner account and get access to your state training report information.
                </p>
              </div>
            </div>

            <form [formGroup]="form" class="mt-8 flex flex-col h-full justify-between">

              <div>
                <div class="w-full flex lg:gap-6">
                  <div class="w-full">
                    <h2 class="learner">
                      Partner Account Admin Information
                    </h2>
                  </div>
                </div>
          
                <div class="w-full flex lg:gap-6">
                  <div class="w-full lg:w-1/2">
                    <mat-form-field class="w-full">
                      <mat-label>First Name</mat-label>
                      <input matInput type="text" formControlName="firstName" />
                      <mat-error *ngIf="form.controls['firstName'].hasError('required') && form.controls['firstName'].touched">Required
                      </mat-error>
                      <mat-error *ngIf="form.controls['firstName'].errors?.['firstName'] && form.controls['firstName'].touched">You
                        must include a valid Full Name.
                      </mat-error>
                      <mat-error *ngIf="form.controls['firstName'].errors?.['message']">{{
                        form.controls['firstName'].getError('message')
                        }}</mat-error>
                    </mat-form-field>
                  </div>
                  <div class="w-full lg:w-1/2">
                    <mat-form-field class="w-full">
                      <mat-label>Last Name</mat-label>
                      <input matInput type="text" formControlName="lastName" />
                      <mat-error *ngIf="form.controls['lastName'].hasError('required') && form.controls['lastName'].touched">Required
                      </mat-error>
                      <mat-error *ngIf="form.controls['lastName'].errors?.['lastName'] && form.controls['lastName'].touched">You
                        must
                        include a valid Last Name.
                      </mat-error>
                      <mat-error *ngIf="form.controls['lastName'].errors?.['message']">{{
                        form.controls['lastName'].getError('message')
                        }}</mat-error>
                    </mat-form-field>
                  </div>
                </div>
          
                <div class="w-full flex lg:gap-6 mt-4">
                  <div class="w-full">
                    <mat-form-field class="w-full">
                      <mat-label>Email</mat-label>
                      <input matInput type="text" (blur)="checkEmail($event, form.controls['email'])" formControlName="email" />
                      <mat-error *ngIf="form.controls['email'].hasError('required') && form.controls['email'].touched">Required
                      </mat-error>
                      <mat-error *ngIf="form.controls['email'].errors?.['email'] && form.controls['email'].touched">You must
                        include a valid email.
                      </mat-error>
                    </mat-form-field>
                    <div [ngClass]="{ 'mb-1 -mt-4 ': form.controls['email'].errors?.['message'] }">
                      <mat-error class="text-danger support-text" *ngIf="form.controls['email'].errors?.['message']">{{
                        form.controls['email'].getError('message')
                        }}</mat-error>
                    </div>
                  </div>
                </div>
          
          
                <div class="w-full flex lg:gap-6 mt-4">
                  <div class="w-full lg:w-1/2">
                    <mat-form-field class="w-full">
                      <mat-label>Password</mat-label>
                      <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" />
                      <mat-icon matSuffix class="cursor-default eye-hidden" (click)="hide = !hide">{{
                        hide ? 'visibility' : 'visibility_off'
                        }}</mat-icon>
                      <mat-error *ngIf="form.controls['password'].hasError('required') && form.controls['password'].touched">Required
                      </mat-error>
                      <mat-error *ngIf="form.controls['password'].errors?.['password'] && form.controls['password'].touched">You
                        must
                        include a valid password.
                      </mat-error>
                    </mat-form-field>
                    <div [ngClass]="{ 'mb-1 -mt-4 ': form.controls['password'].errors?.['message'] }">
                      <mat-error *ngIf="form.controls['password'].errors?.['message']">{{
                        form.controls['password'].getError('message')
                        }}</mat-error>
                    </div>
                  </div>
                  <div class="w-full lg:w-1/2">
                    <mat-form-field class="w-full">
                      <mat-label>Confirm Password</mat-label>
                      <input matInput [type]="chide ? 'password' : 'text'" formControlName="confirmPassword" appBlockCopyPaste />
                      <mat-icon matSuffix class="cursor-default eye-hidden" (click)="chide = !chide">{{
                        chide ? 'visibility' : 'visibility_off'
                        }}</mat-icon>
                      <mat-error *ngIf="
                            form.controls['confirmPassword'].hasError('required') &&
                            form.controls['confirmPassword'].touched
                          ">You must include confirm password.
                      </mat-error>
                      <mat-error *ngIf="
                            form.controls['confirmPassword'].errors?.['notEquivalent'] &&
                            form.controls['confirmPassword'].touched
                          ">Passwords do not match.
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>

    
    
        
              <div class="w-full">
                <div class="text-center mt-5">
                  <button type="submit" class="btn-primary large" [disabled]="disabled" id="CreateAccountIndividual"
                    (click)="registerUser()">
                    Create a Partner Account
                  </button>
                </div>
    
    
                <div class="mt-3">
                  <div  class="flex flex-wrap text-primary justify-center">
                    <p  class="large">
                      <span class="mr-1">By signing up, you accept our</span>
                      <a href="https://mandatedreportertraining.com/company/terms-of-use" target="_blank" class="primary-link mr-1 underline">Terms Of Use</a>
                      <span class="mr-1">and</span>
                      <a href="https://mandatedreportertraining.com/company/privacy-statement" target="_blank" class="primary-link underline">Privacy Statement</a>
                    </p>
                  </div>
                  <div class="flex text-primary justify-center">
                    <p class="large"><span class="mr-1">Already have an account?</span>
                      <a href="/login" class="primary-link underline">Log In.</a>
                    </p>
                  </div>
                </div>
    
    
    
              </div>
        
            </form>
          </div>
  

    } @else {
      <!-- <div class="site-container-1000 mt-8">
        <div class="w-full">
          <div class="w-full text-center">
            <h1>Create an Account</h1>
            <p>
              Create an partner account and get access to your state training report information.
            </p>
          </div>
        </div>
      </div>

      <h2>Setup Two Factor Authentication</h2>
      <div id="qrCode" class="w-[200px]"></div>
      <form [formGroup]="otpForm">
        <mat-form-field>
          <mat-label>Authenticator Code</mat-label>
          <input matInput type="text" formControlName="verificationCode" />
          <mat-error *ngIf="otpForm.controls['verificationCode'].errors?.['wrong'] && otpForm.controls['verificationCode'].touched">
            That code didn't work
          </mat-error>
        </mat-form-field>
        <button type="submit" (click)="onSubmit2fa()" [disabled]="disabled" class="btn btn-primary mb-10 mt-10">
          Verify
        </button>
      </form> -->
    }
}