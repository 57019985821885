import { Injectable, signal } from '@angular/core';
import { BehaviorSubject, Observable, map, switchMap, tap } from 'rxjs';
import { AuthService } from '../../api/services/auth.service';
import { UserService } from '../../api/services/user.service';
import { Stores } from '../../api/services/stores';

//TODO generate swagger types
//export type User = components['schemas']['UserViewModel'];

@Injectable({
  providedIn: 'root',
})
export class UserAuthService {
  user$: any = signal(null);
  private userStore$ = new BehaviorSubject<{ jwt: string | null; loggedIn: boolean; user?: any }>({
    jwt: null,
    loggedIn: false,
  });

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private stores: Stores
  ) {
    const token = window.localStorage.getItem('token');
    if (token) {
      this.userStore$.next({ jwt: token, loggedIn: true });
    }

    this.userStore$.subscribe((store) => {
      if (store.jwt) {
        this.loadUserDetails();
      }
    });
  }

  login(email: string, password: string, verificationCode: string) {
    return this.authService.login2fa(email, password, verificationCode).pipe(
      tap((response) => {
        if (response !== null) {
          this.setToken(response.jwt!);
        }
      }),
      switchMap(() => this.userService.getUser()),
      tap((user) => {
        this.user$.set(user);
        if (user !== null) {
          this.userStore$.next({ jwt: this.userStore$.value.jwt, loggedIn: true, user });
        }
      }),
    );
  }

  loadUserDetails() {
    if (!this.userStore$.value.jwt) {
      this.userStore$.next({ jwt: null, loggedIn: false });
      return;
    }

    if (this.userStore$.value.user) {
      return;
    }

    this.userService.getUser().subscribe((user: any) => {
      this.user$.set(user);
      this.userStore$.next({ ...this.userStore$.value, loggedIn: true, user });
    });
  }

  public setToken(jwt: string) {
    window.localStorage.setItem('token', jwt);
    this.userStore$.next({ jwt, loggedIn: true });
  }

  public logout() {
    window.localStorage.removeItem('token');
    this.stores.resetStores();
    this.userStore$.next({ jwt: null, loggedIn: false });
  }

  get isLoggedIn() {
    return this.userStore$.value.loggedIn;
  }

  public userHasRole(role: string): Observable<boolean> {
    return this.userStore$.pipe(
      map((store) => {
        if (!store.user) {
          return false;
        }
        return store.user.roles.includes(role);
      }),
    );
  }
}
