import { Injectable, computed, signal } from "@angular/core";
import { BaseService } from "../base.service";

@Injectable({
  providedIn: 'root'
})
export class LookupsService extends BaseService {
  statesLookup = signal<any[]>([]);
  trainingsLookup = signal<any[]>([]);
  coursesLookup = signal<any[]>([]);
  entityStatusesLookup = signal<any[]>([]);
  courseStatusesLookup = signal<any[]>([]);
  rolesLookup = signal<any[]>([]);
  industriesLookup = signal<any[]>([]);

  getStates() {
    this.http.get('lookup/states').subscribe((states: any) => {
      this.statesLookup.set(this.stateMapLookup(states));
    });
  }

  getTrainings() {
    return this.http.get('lookup/trainings').subscribe((trainings: any) => {
      this.trainingsLookup.set(this.mapLookup(trainings));
    });
  }

  getCourses() {
    return this.http.get('lookup/courses').subscribe((courses: any) => {
      this.coursesLookup.set(this.mapLookup(courses));
    });
  }

  getEntityStatuses() {
    return this.http.get('lookup/entity-status').subscribe((status: any) => {
      this.entityStatusesLookup.set(this.mapLookup(status));
    })
  }

  getCourseStatuses() {
    return this.http.get('lookup/user-scormtrack-status').subscribe((status: any) => {
      this.courseStatusesLookup.set(this.mapLookup(status));
    })
  }

  getRoles() {
    return this.http.get('lookup/roles').subscribe((roles: any) => {
      this.rolesLookup.set(this.mapLookup(roles));
    })
  }

  getIndustries() {
    return this.http.get('lookup/industries').subscribe((industries: any) => {
      this.industriesLookup.set(this.mapLookup(industries));
    })
  }

  mapLookup(lookup: any) {
    return lookup.map((item: any) => {
      return { value: item.key, label: item.text };
    });
  }

  stateMapLookup(lookup: any) {
    return lookup.map((item: any) => {
      return { value: item.key, label: item.text, text: item.abbr };
    });
  }
}
