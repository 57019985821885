import { NgIf, NgClass } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  AbstractControlOptions,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { AccountService } from '../../../../api/services/account.service';
import { map, switchMap, tap } from 'rxjs';
import { UserAuthService } from '../../../../store/user/user-auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { VerifyType } from '../verify-email/verify-email.component';

function equalTo(key: string, confirmationKey: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const input = control.get(key);
    const confirmationInput = control.get(confirmationKey);

    if (input && confirmationInput && input.value !== confirmationInput.value) {
      confirmationInput.setErrors({ notEquivalent: true });
      return { notEquivalent: true };
    } else {
      return null;
    }
  };
}

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  standalone: true,
  imports: [NgIf, RouterLink, MatFormFieldModule, ReactiveFormsModule, NgClass, MatInputModule, MatIconModule],
})
export class RegisterComponent implements OnInit {
  loading = false;
  private inviteGuid?: string;
  form: FormGroup = new FormGroup({});
  otpForm: FormGroup = new FormGroup({});
  hide = true;
  chide = true;
  disabled = false;
  regSuccessful = false;
  formOptions!: AbstractControlOptions;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private accountService: AccountService,
    private userAuthService: UserAuthService,
    private snackBar: MatSnackBar,
    private router: Router,
  ) {
    const formOptions: AbstractControlOptions = {
      validators: [equalTo('password', 'confirmPassword')],
    };

    this.formOptions = formOptions;
  }

  ngOnInit() {
    this.form = this.fb.group(
      {
        email: this.fb.control({ value: null, disabled: true }, Validators.required),
        firstName: this.fb.control(null),
        lastName: this.fb.control(null),
        password: this.fb.control(null, Validators.required),
        confirmPassword: this.fb.control(null, Validators.required),
        inviteGuid: this.fb.control(null, Validators.required),
        externalIdentityNumber: this.fb.control(null),
      },
      this.formOptions,
    );


    this.loading = true;
    this.route.queryParams
      .pipe(
        map((params) => {
          const inviteGuid: string | null = params['invite'];
          if (inviteGuid) {
            this.form.get('inviteGuid')?.setValue(inviteGuid);
            return inviteGuid;
          } else {
            this.loading = false;
          }
          return null;
        }),
        switchMap((inviteGuid) => (inviteGuid ? this.accountService.getInviteDetails(inviteGuid!) : [])),
      )
      .subscribe((inviteDetails: any) => {
        if (inviteDetails) {
          this.form.get('email')?.setValue(inviteDetails.email);
          this.form.get('firstName')?.setValue(inviteDetails.firstName);
          this.form.get('lastName')?.setValue(inviteDetails.lastName);
        }

        this.loading = false;
      });
  }

  checkEmail(email: any, control: any) {}

  registerUser() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    this.disabled = true;
    
      const { email, password, externalIdentifications } = this.form.getRawValue();
      const formValues = {
        ...this.form.getRawValue(),
      };
      if (this.form.get('externalIdentityNumber')?.value) {
        formValues.externalIdentifications = [
          {
            typeId: 1, // TODO there is only one for now
            number: this.form.get('externalIdentityNumber')?.value,
          },
        ];
      }
      this.accountService
        .registerUserTotp(formValues)
        .subscribe({
          next: () => {
            const email = this.form.get('email')?.value;
            const forgot = false;
            this.snackBar.open('Successfully Registered', 'Close', { duration: 3000 });
            this.router.navigateByUrl('/verify-email', {
              state: { email, forgot, verifyType: VerifyType.VerifyEmail },
            });
            this.disabled = false;
          },
          error: (error) => {
            this.snackBar.open('Error registering user', 'Close', { duration: 3000 });
            this.disabled = false;
          },
        });
  }

  onSubmit2fa() {
    if (this.otpForm.valid) {
      this.disabled = true;
      this.userAuthService
        .login(this.otpForm.value.email, this.otpForm.value.password, this.otpForm.value.verificationCode)
        .subscribe({
          next: () => {
            this.snackBar.open('Login successful', 'Close', { duration: 3000 });
            this.disabled = false;
            this.router.navigate(['/dashboard']);
          },
          error: (error) => {
            this.snackBar.open('Invalid 2FA code', 'Close', { duration: 3000 });
            this.disabled = false;
          },
        });
    }
  }
}
